<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="entity.name"
      :root="$t('EducationPrograms')"
      :root-location="businessDashLink('education/education_programs')"
      :navigation-return-route="'education/education_programs'"
      :subtitle="$t('wsu.education.education_program.editor_title')"
      icon="mdi-account"

      :header-action="items.length === 0 ? openImportDialog : null"
      :header-action-text="$t('Import')"

      fill-height
  >

    <template #default>
      <h4 class="text-right">{{ $t('Credits') }}: {{ totalCredits  }} / {{ entity.credits }}</h4>

      <div v-for="(topic, i) in topics" :key="topic.uuid + i">
        <v-hover #default="{hover}">
        <!-- Header-->
        <div class="d-flex justify-space-between align-center">
          <!-- Title-->
          <div class="d-flex align-center">
            <h3 class="py-6 pr-3"> {{ topic.name }}</h3>
            <v-fade-transition >
              <v-btn @click="openEditTopic(topic)" :color="wsACCENT" v-if="hover" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-fade-transition>
          </div>

          <!-- Total Credits-->
          <h5>{{ $t('Credits') }}: {{ topicCredits(topic.uuid) }}</h5>

        </div>
        </v-hover>
        <!-- Items-->
        <ws-data-table
            v-if="topicItems[topic.uuid].length > 0"
            :items="topicItems[topic.uuid] || []"
            :headers="headers"
            dense
            enable-drag
            @table-drag-end="handleReoder($event ,i)"
            disable-pagination
        >

          <template #item.order_number="{item}">
            <h5 v-if="item.type !== 'on_choice_discipline'" class="text-no-wrap">OK {{ item.order_number }}   </h5>
            <h5 v-else class="text-no-wrap">ВК {{ item.on_choice_number }}   </h5>
          </template>

          <template #item.discipline_id="{item , index}">
            <div class="d-flex align-center">
              <ws-select
                  v-if="item.type === 'discipline' || item.type === 'course_project'"
                  @input="editComponent( item , index)"
                  v-model="item.discipline_id"
                  :items="specialtyDisciplines"
                  :placeholder="item.type === 'course_project' && !item.discipline_id ? item.name : $t('wsu.structure.discipline.choose')"
                  background-color="transparent"
                  @new-data="handleNewDiscipline($event , item , index )"
                  autocomplete
                  allow-new-data
                  new-data-text="wsu.structure.discipline.add"
                  width="100%"
                  flat solo
                  :append-icon="null"
                  table-style-primary
                  clearable-on-hover
              />
              <ws-select
                  v-else-if="item.type === 'practice'"
                  @input="editComponent( item , index)"
                  v-model="item.practice_id"
                  :items="practiceTypesSelect"
                  :placeholder="$t('wsu.education.education_program.practice.choose')"
                  background-color="transparent"
                  @new-data="handleNewPracticeType($event , item , index )"
                  autocomplete
                  allow-new-data
                  new-data-text="wsu.education.education_program.practice.add"
                  width="100%"
                  flat solo
                  :append-icon="null"
                  table-style-primary
                  clearable-on-hover
              />
              <h5 v-else :style="`color : ${wsACCENT}`">
                {{ item.name }}
              </h5>
            </div>
          </template>

          <template #item.type="{item}">

            <ft-select
                @input="editComponent(item)"
                v-model="item.type"
                :items="componentTypesSelect"
            >
              <template #default="{text}">
                <h5 class="text-center text-no-wrap" :style="!item.type ? `color : #9AB2C4 ` : ''" >
                  {{ text }}
                  <v-icon :color="!item.type ? '#9AB2C4' : null">mdi-menu-down</v-icon>
                </h5>
              </template>

            </ft-select>
          </template>

          <template #item.evaluation_type="{item , index}">
            <div v-if="['discipline', 'on_choice_discipline'].includes(item.type)">
              <ft-select
                  @input="editComponent(item)"
                  v-model="item.control"
                  :items="EVALUATION_SELECT"
                  multiple
                  new-line-value
              >
                <template #default="{array}">
                  <h5 class="text-no-wrap d-flex " :style="!item.control ? `color : #9AB2C4 ` : ''" >
                    <div>
                      <span v-if="array.length === 0">{{$t('Evaluation') }}</span>
                      <div v-for="value in array" :key="index + value"  >
                        {{ value }}
                      </div>
                    </div>

                    <v-icon :color="!item.control ? '#9AB2C4' : null">mdi-menu-down</v-icon>
                  </h5>
                </template>

              </ft-select>
            </div>
          </template>

          <template #item.credits="{item}">
            <ws-text-field
                width="80"
                @change="editComponent(item)"
                v-model="item.credits"
                number
                number-length="5"
                hide-number-handle
                solo
                flat
                background-color="transparent"
                :placeholder="$t('Credits')"
                table-style
            >
            </ws-text-field>
          </template>

          <template #item.action="{item , hover}">
            <v-sheet width="28" color="transparent">
              <v-fade-transition>
                <v-btn v-if="hover" @click="deleteComponent(item , i)" icon :color="wsACCENT">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-fade-transition>
            </v-sheet>
          </template>

        </ws-data-table>

        <div v-else>
          <div class="d-flex justify-center">
            <v-icon  size="80" :color="wsDARKLIGHT">mdi-assistant</v-icon>
          </div>
          <h4  class="text-center my-6 mb-8">{{ $t('wsu.education.education_program_component.no_items_description') }}</h4>
        </div>

        <div class="d-flex align-center justify-center pb-16" style="width: 100%">
          <v-sheet v-if="i === topics.length - 1" class="wsRoundedLight mr-2" :style="`border : 1px solid ${wsACCENT}`">
            <v-btn @click="addTopic" text class="noCaps" :color="wsACCENT">
              <h5>{{ $t('Topic') }}</h5>
            </v-btn>
          </v-sheet>
          <v-sheet class="wsRoundedLight d-flex align-center" :style="`border : 1px solid ${wsACCENT}`">
            <template v-for="(type,index) in componentTypesSelect" >
              <template v-if="index < 2">
                <v-btn @click="addComponent(type.value , topic.uuid , i)" text class="noCaps" :color="wsACCENT" :key="index + i + 'type'">
                  <h5>{{ type.text}}</h5>
                </v-btn>
                <v-divider  v-if="index !== componentTypesSelect.length - 1" :key="index + 'divider' + i"
                            vertical :style="`border-color : ${wsACCENT}`"  />
              </template>
            </template>
            <ft-select
                @input="addComponent($event , topic.uuid , i)"
                :items="componentTypesSelectMore">
              <v-btn text class="noCaps pt-1 pr-n2" :color="wsACCENT" :key="i + 'more_types'">
                <h5 class="mr-n1">{{ $t('More')}} <v-icon :color="wsACCENT">mdi-menu-down</v-icon></h5>
              </v-btn>
            </ft-select>
          </v-sheet>
        </div>
      </div>

    </template>

    <template #dialog>
      <!-- Add Discipline-->
      <ws-dialog
          v-if="displayDisciplineDialog"
          v-model="displayDisciplineDialog"
          :title="$t('wsu.structure.discipline.add')"
          @save="addNewDiscipline"
          width="700"
      >
        <ws-text-field
            class="mt-5"
            v-model="disciplineEntityData.name"
            :label="$t('wsu.structure.discipline.name.title')"
            :placeholder="$t('wsu.structure.discipline.name.placeholder')"
            :error="disciplineNameError"
            @input="disciplineNameError = false"
        />

        <v-expand-transition>
          <ws-select
              class="pt-5"
              v-if="disciplineEntityData.is_general "
              v-model="disciplineEntityData.department_id"
              :items="departmentsSelect"
              :label="$t('wsu.structure.department.name.title')"
              :placeholder="$t('wsu.structure.department.name.placeholder')"
              :error="departmentError"
              @input="departmentError = false"
          />
        </v-expand-transition>

        <ws-select
            :class="disciplineEntityData.is_general ? null : 'mt-5'"
            v-model="disciplineEntityData.degrees_id"
            :items="disciplineEntityData.is_general ? degreesSelect : specialtyDegreesSelect"
            :label="$t('Degree')"
            :placeholder="$t('wsu.structure.degree.choose')"
            :error="degreeError"
            @input="degreeError = null"
            multiple
        />

        <v-expand-transition>
          <ws-button-group
              v-if="!disciplineEntityData.is_general"
              v-model="disciplineParent"
              :label="$t('StructuredTo')"
              :items="disciplineParentSelect"
          />
        </v-expand-transition>


        <ws-switch
            class="mt-3"
            @input="handleGeneralDisciplineChange"
            :class="!disciplineEntityData.is_general ? 'mt-6' : 'mt-3'"
            :placeholder="$t('wsu.structure.discipline.general')"
            :tooltip="$t('wsu.structure.discipline.general_tooltip')"
            v-model="disciplineEntityData.is_general"
        />

      </ws-dialog>
      <!-- Add Practice Type -->
      <ws-dialog
          :title="$t('wsu.education.education_program.practice.choose')"
          v-model="displayPracticeDialog"
          @save="addEditPracticeType"
      >
        <ws-text-field
            v-model="practiceEntityData.name"
            :label="$t('Name')"
            :placeholder="$t('wsu.education.education_program.practice.name_placeholder')"
            :error="practiceNameError"
            @input="practiceNameError = false"
        />
      </ws-dialog>
      <!-- Edit Topic -->
      <ws-dialog
          :title="$t('EditTopic')"
          v-model="displayTopicDialog"
          @save="editTopic"
          @delete="deleteTopic"
          show-delete
      >
        <ws-text-field
            v-model="topicEntityData.name"
            :label="$t('Name')"
            :placeholder="$t('EditName')"
        />

      </ws-dialog>

      <!--  Import Programs -->
      <ws-file-import-dialog
          v-if="displayImportDialog"
          @success="initPage"
          v-model="displayImportDialog"
          entity="education_program"
          :body="{education_program_id : uuid }"
      />

    </template>

  </sub-page>
</template>

<script>
import {mapActions, mapState} from "vuex";

import wsFileImportDialog from "@/components/UI/wsFileImportDialog";

export default {
  name: "educationProgramEditor",
  components : {
    wsFileImportDialog
  },
  props : {
    uuid : {
      type : String
    }
  },
  data() {
    return {
      entity : {},
      specialtyDisciplines : [],
      departmentsSelect : [],
      degreesSelect : [],
      practiceTypesSelect : [],

      specialtyDegreesSelect : [],
      items : [],
      topics : [],
      selectedComponent : {},
      disciplineEntityData : {},
      topicEntityData : {},
      practiceEntityData : {},

      displayDisciplineDialog : false,
      displayTopicDialog : false,
      displayPracticeDialog : false,

      disciplineNameError : false,
      practiceNameError : false,
      departmentError : false,
      disciplineParent : 'program',
      displayImportDialog : false
    }
  },
  computed : {
    ...mapState('structure' , [
      'selectedLang'
    ]),
    disciplineParentSelect() {
      return [
        { text : this.$t('ToEducationProgram') , value : 'program' },
        { text : this.$t('ToDepartment') , value : 'department' },
      ]
    },
    onChoiceAmount() {
      return this.items.filter(el => el.type === 'on_choice_discipline').length
    },
    totalCredits() {

      let credits = 0
      this.items.forEach(item => {
        if ( item.credits ) {
          console.log('item.credits' , item.credits )
          credits = credits + Number.parseFloat(item.credits)
          console.log('credits = ' +  credits)
        }
      })

      return credits

    },

    topicItems() {
      let object = {}
      this.topics.forEach(topic => {
        object[topic.uuid] = this.getTopicItems(topic.uuid)
      })
      return object
    },
    componentTypesSelect() {
      return [
        { text : this.$t('Discipline')    , value : 'discipline'     },
        { text : this.$t('OnChoiceDiscipline') , value : 'on_choice_discipline' },
        { text : this.$t('Practice')      , value : 'practice'       },
        { text : this.$t('CourseProject') , value : 'course_project' },
        { text : this.$t('StateAttestation') , value : 'state_attestation' },
      ]
    },
    componentTypesSelectMore() {
      return this.componentTypesSelect.filter((_,index) => index >= 2)
    },
    headers() {
      return [
        { value : 'order_number' , width: 10 },
        { text : this.$t('Component') , value : 'discipline_id' },
        { text : this.$t('Type') , value : 'type' , width: 10},
        { text : this.$t('ControlForm') , value : 'evaluation_type' ,  width : 40 },
        { text : this.$t('Credits') , value : 'credits' , width : 50 },
        { value : 'action' , width: 10 }
      ]
    },
  },
  methods : {
    ...mapActions('wsu_education' , [
      'GET_EDUCATION_PROGRAM' ,
      'ADD_EDIT_EDUCATION_PROGRAM' ,
      'ADD_EDIT_EDUCATION_PROGRAM_COMPONENT',
      'DELETE_EDUCATION_PROGRAM_COMPONENT',
      'ADD_EDIT_EDUCATION_PROGRAM_TOPIC',
      'DELETE_EDUCATION_PROGRAM_TOPIC',
      'REORDER_EDUCATION_PROGRAM_COMPONENTS',
      'ADD_EDIT_PRACTICE_TYPE',
    ]),
    ...mapActions('structure' , [
      'ADD_EDIT_DISCIPLINE' ,
    ]),

    openImportDialog() {
      this.displayImportDialog = true
    },

    // ENTITIES

    // Components
    async addComponent($event , topic , topicIndex) {

      let orderNumber = this.topicItems[topic].length + 1
      if ( topicIndex > 0 ) {
        this.topics.forEach((item , index) => {
          if ( index < topicIndex ) {
            orderNumber += this.getTopicItems(item.uuid).length
          }
        })
      }
      let data = {
        type : $event,
        education_program_id : this.uuid,
        education_program_topic_id : topic,
        order_number : orderNumber
      }

      let result = await this.ADD_EDIT_EDUCATION_PROGRAM_COMPONENT(data)
      if ( !result ) {
        return
      }
      this.items.push(result)

      let topicsIds =  Object.keys(this.topicItems).filter((_,index) => index > topicIndex)

      if ( topicIndex < this.topics.length - 1 ) {
        this.items.forEach((item, index) => {
          if (topicsIds.includes(item.education_program_topic_id)) {
            this.items[index].order_number++
          }
        })

        await this.reorderComponents()
      }

    },
    async editComponent( item ) {
      if ( item.discipline_id && item.discipline_id.includes('new-data::')) {
        return
      }
      if ( item.practice_id && item.practice_id.includes('new-data::')) {
        return
      }
      let result = await this.ADD_EDIT_EDUCATION_PROGRAM_COMPONENT(item)
      if ( !result ) {
        return
      }

      if ( item.type !== 'on_choice_discipline' && item.on_choice_number ) {
        this.reorderOnChoice(item , true)
      }


      let index = this.items.findIndex(el => el.uuid === item.uuid )

      if ( index === -1 ) {
        return
      }

      this.items[index]  = result
      this.$set(this.items , index , result )
      this.items = this.COPY(this.items)

    },
    async deleteComponent(item , topicIndex) {
      let result = await this.DELETE_EDUCATION_PROGRAM_COMPONENT(item.uuid)
      if ( !result ) {
        return
      }
      let index = this.items.findIndex(el => el.uuid === item.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.reorderOnChoice(item)
      this.handleReoder(this.getTopicItems(item.education_program_topic_id ) , topicIndex )

      let topicsIds =  Object.keys(this.topicItems).filter((_,index) => index > topicIndex)

      if ( topicIndex < this.topics.length - 1 ) {
        this.items.forEach((item, index) => {
          if (topicsIds.includes(item.education_program_topic_id)) {
            this.items[index].order_number--
          }
        })

        await this.reorderComponents()
      }
    },

    // Topics
    async addTopic(){
      let data = {
        education_program_id: this.uuid,
        name : this.$t('EducationProgramTopic'),
        lang : this.selectedLang
      }
      let result = await this.ADD_EDIT_EDUCATION_PROGRAM_TOPIC(data)
      if ( !result ) {
        return
      }
      this.topics.push(result)

    },
    async editTopic(){

      this.topicEntityData.lang = this.selectedLang
      let result = await this.ADD_EDIT_EDUCATION_PROGRAM_TOPIC(this.topicEntityData)
      if ( !result ) {
        return
      }
      let index = this.topics.findIndex(el => el.uuid === this.topicEntityData.uuid)
      if ( index === -1 ) {
        return
      }
      this.topics[index] = result
      this.topics = this.COPY(this.topics)
      this.displayTopicDialog = false

    },
    async deleteTopic() {
      let result = this.DELETE_EDUCATION_PROGRAM_TOPIC(this.topicEntityData.uuid)
      if ( !result ) {
        return
      }
      let index = this.topics.findIndex(el => el.uuid === this.topicEntityData.uuid)
      if ( index === -1 ) {
        return
      }
      this.topics.splice(index , 1)
      this.displayTopicDialog = false
    },

    // Select Entities
    async addNewDiscipline() {

      if ( !this.disciplineEntityData.name ) {
        this.disciplineNameError = true
        return this.notify(this.$t('wsu.structure.discipline.name.error') , 'warning')
      }
      if ( this.disciplineEntityData.is_general && !this.disciplineEntityData.department_id ) {
        this.departmentError = true
        return this.notify(this.$t('wsu.structure.department.choose') , 'warning')
      }


      this.disciplineEntityData.lang = this.selectedLang

      if ( !this.disciplineEntityData.degrees_id )  {
        this.disciplineEntityData.degrees_id = [this.entity.degree_id]
      }

      if ( this.disciplineEntityData.degrees_id.length === 0 )  {
        this.disciplineEntityData.degrees_id = [this.entity.degree_id]
      }
      if ( !this.disciplineEntityData.degrees_id.includes(this.entity.degree_id) )  {
        this.disciplineEntityData.degrees_id.push(this.entity.degree_id)
      }
      if ( !this.disciplineEntityData.is_general ) {
        if ( this.disciplineParent === 'department' ) {
          this.disciplineEntityData.department_id = this.entity.department_id
        } else {
          this.disciplineEntityData.university_specialty_id = this.entity.university_specialty_id
        }
      }


      let result = await this.ADD_EDIT_DISCIPLINE(this.disciplineEntityData)
      if ( !result ) {
        this.notify(this.$t('NetworkError'))
        return
      }

      this.notify(this.$t('wsu.structure.discipline.created') , 'success')

      this.specialtyDisciplines.push({
        value : result.uuid,
        text : result.name,
        specialty_id : result.specialty_id,
        department_id : result.department_id,
        degrees_id : result.degrees_id
      })

      this.displayDisciplineDialog = false

      this.selectedComponent.discipline_id = result.uuid

      this.editComponent( this.selectedComponent )

    },
    async addEditPracticeType() {

      if ( !this.practiceEntityData.name ) {
        this.practiceNameError = true
        return this.notify(this.$t('wsu.education.education_program.practice.name_placeholder') , 'warning')
      }
      this.practiceEntityData.lang = this.selectedLang

      let result = await this.ADD_EDIT_PRACTICE_TYPE(this.practiceEntityData )
      if ( !result ) {
        return
      }
      this.practiceTypesSelect.push(result)

      this.selectedComponent.practice_id = result.value

      this.editComponent( this.selectedComponent )

      this.displayPracticeDialog = false

    },

    // Technical functions
    // Reorder
    async reorderComponents(noUpdate = false) {

      this.items = this.items.sort((a, b) => { return a.order_number - b.order_number } )
      this.items = this.COPY(this.items)

      let orderObject = {}

      this.items.forEach( item => {
        orderObject[item.uuid] = item.order_number
      })
      let data = {
        items : orderObject,
        education_program_id : this.uuid
      }

      if ( !noUpdate ) {
        await this.REORDER_EDUCATION_PROGRAM_COMPONENTS(data)
      }

    },
    reorderOnChoice(item , noCheck = false) {

      if ( item.type !== 'on_choice_discipline' && !noCheck  ) {
        return
      }

      this.items.forEach((el , index) => {
        if ( el.type === 'on_choice_discipline' && el.on_choice_number > item.on_choice_number) {
          this.items[index].on_choice_number--
          this.items[index].name = this.$t('OnChoiceDiscipline') + " " + this.items[index].on_choice_number
        }
      })
      this.items = this.COPY(this.items)
    },
    handleReoder(items , topicIndex ) {
      let newOrderObject = {}
      let previousLength = 0

      if ( topicIndex > 0 ) {
        this.topics.forEach((item , index) => {
          if ( index < topicIndex ) {
            previousLength += this.getTopicItems(item.uuid).length
          }
        })
      }

      items.forEach((item , index) => {
        newOrderObject[item.uuid] = index + 1 + previousLength
      })

      this.items.forEach((item , index) => {
        if ( newOrderObject[item.uuid] ) {
          this.items[index].order_number = newOrderObject[item.uuid]
        }
      })

      this.reorderComponents()

    },

    // handle functions
    handleGeneralDisciplineChange() {
      if ( !this.disciplineEntityData.is_general ) {
        this.disciplineEntityData.department_id = null
        this.disciplineEntityData.degrees_id = [this.entity.degree_id]
        this.disciplineEntityData = this.COPY(this.disciplineEntityData)
      } else {
        this.disciplineEntityData.department_id = this.entity.department_id
      }
    },
    handleNewDiscipline(event , item ) {
      this.selectedComponent = this.COPY(item)

      this.disciplineEntityData = {
        name : event,
        degrees_id :  [this.entity.degree_id]
      }
      this.displayDisciplineDialog = true
    },
    handleNewPracticeType(event , item ) {
      this.selectedComponent = this.COPY(item)

      this.practiceEntityData = {
        name : event,
      }
      this.displayPracticeDialog = true
    },

    //Selectors and counts
    topicCredits(topicId) {

      let credits = 0
      this.items.forEach(item => {
        if ( item.credits && item.education_program_topic_id === topicId ) {
          credits = credits + Number.parseFloat(item.credits)
        }
      })

      return credits

    },
    openEditTopic(item) {
      this.topicEntityData = this.COPY(item)
      this.displayTopicDialog = true
    },
    getTopicItems(topicId) {
      return this.items.filter( el => el.education_program_topic_id === topicId)
    },


    async initPage() {
      let result = await this.GET_EDUCATION_PROGRAM(this.uuid)
      if ( !result ) {
        return
      }
      this.entity = result.education_program || {}
      this.items = this.entity.components
      this.topics = this.entity.topics
      this.specialtyDisciplines = result.specialty_disciplines || []
      this.departmentsSelect = result.departments_select
      this.degreesSelect = result.degrees_select
      this.specialtyDegreesSelect = result.university_specialty_degrees_select
      this.practiceTypesSelect = result.practice_types_select || []
      this.reorderComponents(true)
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>